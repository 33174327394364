html {
    scroll-behavior: smooth;
  }
  .wrappContainer{
    display: grid;
    grid-template-columns: repeat(1,1fr);
    justify-content: center;
}
.section{
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    position:relative;
    width: 400px;
    min-height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    display: grid;
    justify-content: center;
}

.containerHero{
    align-self: flex-end;
    margin-bottom: 5rem;
    z-index: 1;
}
.heroImagen{
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
}
.slogan{
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    color: #fff;
    /*max-height: 100px;*/
    word-wrap: break-word;
    overflow: hidden;
    font-size: 3em;
    margin-bottom: 0.5em;
}
.logo{
    max-width: 200px;
    display: grid;
    justify-content: center;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}
.logo img{
    max-width: 100%;
}
.degradado{
    position: absolute;
    min-width: 100%;
    min-height: 100%;
}
.vistas{
    color: #fff;
    text-align: center;
    margin-top: 1em;
}
.categorias{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 1em;
}
.serviceTag{
    border-radius: 1em;
    height: 250px;
    min-width: 160px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    text-align: center;
}
.degradadoTag{
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    border-radius: 1em;
    cursor: pointer;
}
.ContectService{
    position: absolute;
    color: #fff;
    width: 80%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
    bottom: 0;
    font-weight: 700;
}

.productos{
    display: grid;
    padding: 1em;
    grid-template-columns: repeat(1,1fr);
}
.titulodescripcion{
    color: #fff;
}
.producto{
   background: rgb(0, 0,0,0.5);
   margin-bottom: 1em;
   display: grid;
   grid-template-columns: 30% 60% 10%;
   border-radius: 1em;
}
.producto_imagen{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 1em 0 0 1em;
}
.producto_nombre{
    color: #fff;
    padding: 1em;
}
.productosumar{
    display: grid;
}
.productosumar div{
    align-self: center;
    text-align: center;
    width: 20px;
    height: 20px;
    border-radius: 1em;
    background-color: #fff;
    cursor: pointer;
}
.contentMedia{
    display: grid;
    justify-content: center;
    color: #fff;
    margin-bottom: 5em;
    gap: 1em;
}
.socialMedia{
    display: flex;
    justify-content: space-around;
    color: #fff;
    text-decoration: none;
}
.socialMedia a{
    color: #fff;
}
.NavbarPadre{
    height: 50px;
    display: flex;
    justify-content: space-around;
    color: #fff;
    overflow-x: auto;
    align-content: center;
    padding: 1em;
    position: sticky;
    top: 0;
    padding-left: 1em;
    border-radius: 15px;
}
.NavbarCategoria{
    display: flex;
    color: #fff;
    overflow-x: auto;
    align-content: center;
    padding: 1.5em;
    position: sticky;
    top: 0;
    padding-left: 1em;
}
.NavbarCategoria div{
    margin-right: 1em;
    display: grid;
    align-content: center;
    cursor: pointer;
}
.NavbarPadre::-webkit-scrollbar:horizontal {
    height: 5px;
}
.NavbarPadre::-webkit-scrollbar-thumb {
    background-color: #000;
    border-radius: 20px;
}
.NavbarPadre::-webkit-scrollbar {
    -webkit-appearance: none;
} 



.backbackground{
    bottom: 0;
    left: 0;
    position: fixed;
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    z-index: -1;
}
.backbackground div{
    width: 400px;
}
@media (max-width: 700px) {
  
    .section{
        width: auto;
    }
    .hero{
        width: 100% !important;
    }
}
