@font-face {
    font-family: BwSurco;
    src: url("../fonts/BwSurco.woff") format("woff");
}
@font-face {
    font-family: NexaRust;
    src: url("../fonts/NexaRustScriptLDemo-1.otf") format("otf"),
       url('../fonts/NexaRustScriptLDemo-1.otf')  format('truetype'); /* Safari, Android, iOS */
}

.menu{
    position: fixed;
    bottom: 0;
    min-width: 100vw;

    z-index: 10;
}
.wrapperMenu{
    align-self: end;
    display: grid;
    grid-template-columns: 30% 40% 30%;
}
.buttonSide{
    display: grid;
    justify-content: center;
    align-content: center;
    border-top-left-radius: 15em;
    border-top-right-radius: 15em;
    padding-top: 0.4em;
    color: white;
    font-size: 3em;
    -webkit-box-shadow: 0px -9px 52px -8px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px -9px 52px -8px rgba(0,0,0,0.75);
    box-shadow: 0px -9px 52px -8px rgba(0,0,0,0.75);
    cursor: pointer;
    transition: 0.3s ease-in;
}
.buttonSide:hover{
    font-size: 3.1em;
}
.greenCard{
    display: grid;
    align-content: end;
    cursor: pointer;
}
.greenCard img{
    justify-self: center;
    max-width: 60%;
    transition: 0.5s ease-in-out;
}
.greenCard:hover img{
    transition: 0.5s ease-in-out;
    max-width: 80%;
}
.carrito{
    display:grid;
    justify-content: center;
    align-content: center;
    font-size: 1em;
    color: white;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    box-shadow: -5px 0px 3px #000;
    border-radius: 0px 10px 10px 0px;
    padding: 5px;
}
.whatsApp{
    display:grid;
    justify-content: center;
    align-content: center;
    font-size: 1em;
    color: white;
    transition: 0.5s ease-in-out;
    cursor: pointer;
}
.productLabel{
    background-color: #000;
    font-size: 0.7em;
    border-radius: 10em;
    height: 15px;
    width: 15px;
    position: absolute;
    text-align: center;
    right: 0;
    top: 0;
}
.marketButon{
    position: relative;
    padding: 0.7em;
    border: 1px solid #fff;
    border-radius: 100em;
}
.marketButonAnimation{
    position: relative;
    padding: 0.7em;
    border: 1px solid #fff;
    border-radius: 100em;
    animation: zoom-in-zoom-out 2s ease-out infinite;
}
@keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.5, 1.5);
    }
    100% {
      transform: scale(1, 1);
    }
}
.icon{
    padding: 0.5em;
    border-radius: 1em;
    border: 1px solid white;
}
.whatsApp:hover{
    transition: 0.3s ease-in-out;
    font-size: 1.3em;
}
.NavbarContact{
    z-index: 30;
    transition: 0.3s ease-in-out;
}
.styck{
    position: fixed;
    top: 0;
    width: 100%;
    transition: 0.3s ease-in-out;
}
.ContactMenu{
    padding: 1em;
    display: grid;
    grid-template-columns: repeat(5,1fr);
    justify-content: center;
    align-items: center;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
}
.contactIcon{
    display: grid;
    justify-self: center;
    align-items: center;
    justify-content: center;
    padding: 0.5em;
    border-radius: 1em;
    border: 1px solid white;
}
.contactIcon{
    display: grid;
    justify-self: center;
    align-items: center;
    justify-content: center;
    padding: 0.5em;
    border-radius: 1em;
    border: 1px solid white;
}
.logo3{
    display: grid;
    justify-content: center;
}
.logo3 img{
    display: grid;
    justify-self: center;
    width: 80%;
}
.Gallery{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-column-gap: 0.5em;
    row-gap: 0.5em;
    margin-top: 0.5em;
    min-height: 5em;

}
.imgGallery{
    border-radius: 0.5em;
    min-height: 5em;
    background-position: top;
    background-size: cover
}

.loading{
    background-color: #021e31;
    left: 0;
    top: 0;
    min-width: 100%;
    min-height: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    position: absolute;
}
.logoCarga{
    animation: fill 0.5s ease forwards 1s;
}
.logoCarga path:nth-child(1){
    stroke-dasharray: 150;
    stroke-dashoffset: 150;
    animation: line-anim 2s ease forwards;
}
.logoCarga path:nth-child(2){
    stroke-dasharray: 70;
    stroke-dashoffset: 70;
    animation: line-anim 2s ease forwards 0.1s;
}
.logoCarga path:nth-child(3){
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    animation: line-anim 2s ease forwards 0.2s;
}
.logoCarga path:nth-child(4){
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    animation: line-anim 2s ease forwards 0.3s;
}
.logoCarga path:nth-child(5){
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    animation: line-anim 2s ease forwards 0.4s;
}
.logoCarga path:nth-child(6){
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    animation: line-anim 2s ease forwards 0.5s;
}
.logoCarga path:nth-child(7){
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    animation: line-anim 2s ease forwards 0.6s;
}
.logoCarga path:nth-child(8){
    stroke-dasharray: 70;
    stroke-dashoffset: 70;
    animation: line-anim 2s ease forwards 0.7s;
}
.logoCarga path:nth-child(9){
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    animation: line-anim 2s ease forwards 0.8s;
}
.logoCarga path:nth-child(10){
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    animation: line-anim 2s ease forwards 0.8s;
}
.logoCarga path:nth-child(11){
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    animation: line-anim 2s ease forwards 1s;
}
.handleCick{
    color: #fff;
    background-color: rgb(88, 21, 37);
    z-index: 2;
    display: flex;
    list-style: none;
    justify-content: center;
    cursor: pointer;
    position: relative;
    padding: 0.3em;
    /*border: 1px solid #fff;*/
    border-radius: 100em;
    width: auto;
    margin: 3% 20% 0 20%;
}
.idiomas{
    color: #fff;
    z-index: 2;
    display: flex;
    list-style: none;
    justify-content: center;
}
.idiomas-item{ 
    position: relative;
    margin: 0 2em;
    line-height: 2rem;
    cursor: pointer;
}
.idiomas-item::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: white;
    left: 0;
    bottom: 0;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 650ms cubic-bezier(0.68,-0.55,0.265,1.55);
}
.idiomas-item:hover::before{
    transform: scaleX(1);
}
@keyframes line-anim{
    to{
        stroke-dashoffset: 0;
    }
}
@keyframes fill{
    from {
        fill: transparent;
    }
    to{
        fill: #59DDEC;
    }
}
@media(max-width:768px){
    .menu{
        max-width: 100vw;
    }
  
}
@media (min-width: 768px) {
    
    .styck{
        width: 25%;
        margin: auto;
        left: 0;
        right: 0;
    }
    .wrapperMenu{
        max-width: 50vh;
        margin:auto;
    }
    .buttonSide{
        font-size: 2.5em;
    }
  }